import Vue from "vue";
import swal2 from "sweetalert2";
const swal = swal2.mixin({
  confirmButtonText: "Close",
  confirmButtonClass: "btn-primary btn-alert",
});
Vue.prototype.successAlert = (message = "", title = "Success !") =>
  new swal({
    title: `<p class='alert-text mb-3'>${title} </p>`,
    text: message,
    icon: "success",
  });
Vue.prototype.errorAlert = (message = "error message", title = "Error !") =>
  new swal({
    title: `<p class='alert-text mb-3'>${title}</p>`,
    html: message,
    icon: "error",
  });
Vue.prototype.warningAlert = (
  message = "warning message",
  title = "Warning !"
) =>
  new swal({
    title: `<p class='alert-text mb-3'>${title}</p>`,
    text: message,
    icon: "warning",
  });
Vue.prototype.infoAlert = (message = "Info message", title = "Info !") =>
  new swal({
    title: `<p class='alert-text mb-3'>${title}</p>`,
    text: message,
    icon: "info",
  });
Vue.prototype.confirmAlert = (options = {}) => {
  let { message, title, confirmButtonText, cancelButtonText, icon } = options;
  if (!message) {
    message = "You can't revert your action";
  }
  if (!title) title = "Are you sure?";
  if (!confirmButtonText) confirmButtonText = "Confirm";
  if (!cancelButtonText) cancelButtonText = "Cancel";
  if (!icon) icon = "warning";

  return new swal({
    title: `<p class='alert-text'>${title}</p>`,
    html: `<p>${message} <hr/></p>`,
    icon: icon,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    confirmButtonClass: "btn-primary btn-alert",
    cancelButtonText: cancelButtonText,
    cancelButtonClass: "btn-alert",
    reverseButtons: true,
  });
};

export default alert;
