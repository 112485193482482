import Vue from "vue";
import axios from "axios";

import apiPath from "@/plugins/api";

export const state = () => ({
  state: {
    stampCardList: [],
    modalAlertLogin: false,
    productList: {},
    stampCardDetail: {},
    respCreateStampInfo: {},
    respCreateStampDetail: {},
    respCreateStampDetailList: {},
    respUpdateStampInfo: {},
    respUpdateStampCardDetail: {},
    respUpdateStampCardDetailList: {},
    respDeleteStampCard: {},
    respDeleteStampCardDetail: {},
  },
});

export const mutations = {
  setStampCardList: async function (state, val) {
    state.stampCardList = val;
  },
  setModalAlertLogin: async function (state, val) {
    state.modalAlertLogin = val;
  },
  setProductList: async function (state, val) {
    state.productList = val;
  },
  setStampCardDetail: async function (state, val) {
    state.stampCardDetail = val;
  },
  setCreateStampInfo: async function (state, val) {
    state.respCreateStampInfo = val;
  },
  setCreateStampDetail: async function (state, val) {
    state.respCreateStampDetail = val;
  },
  setCreateStampDetailList: async function (state, val) {
    state.respCreateStampDetailList = val;
  },
  setUpdateStampInfo: async function (state, val) {
    state.respUpdateStampInfo = val;
  },
  setUpdateStampCardDetail: async function (state, val) {
    state.respUpdateStampCardDetail = val;
  },
  setUpdateStampCardDetailList: async function (state, val) {
    state.respUpdateStampCardDetailList = val;
  },
  setDeleteStampCard: async function (state, val) {
    state.respDeleteStampCard = val;
  },
  setDeleteStampCardDetail: async function (state, val) {
    state.respDeleteStampCardDetail = val;
  },
};

export const actions = {
  getStampCardList: async ({ commit }, payload) => {
    await axios.post(`${apiPath}/stampcard/list`, payload).then(
      async (data) => {
        commit("setStampCardList", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  getProductStamp: async ({ commit }, payload) => {
    await axios
      .post(`${apiPath}/stampcard/${payload.id}/product`, payload.form)
      .then(
        async (data) => {
          commit("setProductList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  getStampCardById: async ({ commit }, payload) => {
    await axios.get(`${apiPath}/stampcard/${payload}`).then(
      async (data) => {
        commit("setStampCardDetail", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  createStampCardInfo: async ({ commit }, payload) => {
    await axios.post(`${apiPath}/stampcard`, payload).then(
      async (data) => {
        commit("setCreateStampInfo", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  createStampCardDetail: async ({ commit }, payload) => {
    await axios
      .post(`${apiPath}/stampcard/${payload.id}/item`, payload.form)
      .then(
        async (data) => {
          commit("setCreateStampDetail", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  createStampCardList: async ({ commit }, payload) => {
    await axios
      .post(`${apiPath}/stampcard/${payload.id}/list`, payload.form)
      .then(
        async (data) => {
          commit("setCreateStampDetailList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updateStampCardInfo: async ({ commit }, payload) => {
    await axios.put(`${apiPath}/stampcard/${payload.id}`, payload.form).then(
      async (data) => {
        commit("setUpdateStampInfo", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  updateStampCardDetail: async ({ commit }, payload) => {
    await axios
      .put(`${apiPath}/stampcard/${payload.id}/item`, payload.form)
      .then(
        async (data) => {
          commit("setUpdateStampCardDetail", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  updateStampCardList: async ({ commit }, payload) => {
    await axios
      .put(`${apiPath}/stampcard/${payload.id}/list`, payload.form)
      .then(
        async (data) => {
          commit("setUpdateStampCardDetailList", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
  deleteStampCard: async ({ commit }, payload) => {
    await axios.delete(`${apiPath}/stampcard/${payload}`).then(
      async (data) => {
        commit("setDeleteStampCard", data.data);
      },
      (error) => {
        const code = parseInt(error.response && error.response.status);
        if (code === 401) {
          commit("setModalAlertLogin", true);
        }
      }
    );
  },
  deleteStampCardDetail: async ({ commit }, payload) => {
    await axios
      .delete(`${apiPath}/stampcard/deleteStampcardDetail`, {
        // headers: {
        //   'Content-Type': 'application/json',
        //   'Accept': 'application/json',
        //   'Authorization': `Bearer ${VueCookies.get('back_office_admin_token').token}`,
        //   'API-KEY': apiPath_KEY
        // },
        data: payload,
      })
      .then(
        async (data) => {
          commit("setDeleteStampCardDetail", data.data);
        },
        (error) => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            commit("setModalAlertLogin", true);
          }
        }
      );
  },
};

export default {
  state,
  actions,
  mutations,
};
