const ShippingRoute = {
  path: "",
  name: "",
  redirect: "shipping",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  meta: {
    requiresAuth: true,
    activeUrl: "/setting",
    isChildren: true,
    lv: 2,
  },
  children: [
    {
      path: "shipping",
      name: "Shipping",
      redirect: "shipping",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: true,

        activeUrl: "/setting",
        isChildren: true,
        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () =>
            import("@/views/pages/setting/settingPage/Shipping/index.vue"),
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 3,
          },
        },
        {
          path: "shipping-methods",
          name: "Shipping Methods",
          redirect: "/setting/",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/Shipping/ShippingMethods"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            {
              path: ":id",
              name: "Shipping Methods Detail",
              redirect: "/setting/ShippingMethods",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/Shipping/ShippingMethods/detail.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
              ],
            },
            // {
          ],
        },
        {
          path: "shipping-group",
          name: "Shipping Group",
          redirect: "/setting/shipping/shipping-group",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              component: () =>
                import(
                  "@/views/pages/setting/settingPage/Shipping/ShippingGroup"
                ),
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
            },
            {
              path: ":id",
              name: "Shipping Group Detail",
              redirect: "/setting/shipping/shiping-group",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/Shipping/ShippingGroup/detail.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export { ShippingRoute };
