const Transaction = {
  path: "",
  name: "",
  redirect: "/report/transaction",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/report/transaction",
      redirect: "/report/transaction",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Transaction",
      meta: {
        requiresAuth: true,
        activeUrl: "/report/transaction",
        parent: "/report/transaction",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/report/transaction"),
          meta: {
            requiresAuth: true,
            activeUrl: "/report/transaction",
            parent: "/report/transaction",

            lv: 2,
          },
        },
        {
          path: ":id",
          name: "Transaction Detail",
          component: () =>
            import("@/views/pages/report/transaction/detail/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/report/transaction",
            parent: "/report/transaction",

            lv: 3,
          },
        },
        {
          path: "/transaction/configdata",
          name: "Config Data",
          component: () =>
            import("@/views/pages/transaction/import/configField.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/report/transaction",
            parent: "/report/transaction",
            lv: 3,
          },
        },
        {
          path: "/transaction/importdata",
          name: "Import Data",
          component: () =>
            import("@/views/pages/transaction/import/ImportFile.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/report/transaction",
            parent: "/report/transaction",
          },
        },
        {
          path: "/transaction/checkfield",
          name: "Transaction Check Data",
          component: () =>
            import("@/views/pages/transaction/import/checkField.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/report/transaction",
            parent: "/report/transaction",
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
const TransactionPending = {
  path: "",
  name: "",
  redirect: "/report/transaction-pending",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/report/transaction-pending",
      redirect: "/report/transaction-pending",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Transaction Pending",
      meta: {
        requiresAuth: true,
        activeUrl: "/report/transaction-pending",
        parent: "/report/transaction-pending",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/transactionPending"),
          meta: {
            requiresAuth: true,
            activeUrl: "/report/transaction-pending",
            parent: "/report/transaction-pending",

            lv: 2,
          },
        },
        {
          path: ":id",
          name: "Transaction Pending Detail",
          component: () => import("@/views/pages/transactionPending/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/report/transaction-pending",
            parent: "/report/transaction-pending",

            lv: 3,
          },
        },
        {
          path: "original/:id",
          name: "Transaction Pending Original",
          component: () => import("@/views/pages/transactionPending/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/report/transaction-pending",
            parent: "/report/transaction-pending",
            original: true,
            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export { Transaction, TransactionPending };
