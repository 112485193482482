const InventoryTransfer = {
  path: "",
  name: "",
  redirect: "inventory-transfer",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "inventory-transfer",
      redirect: "inventory-transfer",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Inventory Transfer",
      meta: {
        requiresAuth: true,
        activeUrl: "/inventory-transfer",
        parent: "/inventory-transfer",

        lv: 1,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/inventoryTransfer"),
          meta: {
            requiresAuth: true,
            activeUrl: "/inventory-transfer",
            parent: "/inventory-transfer",

            lv: 2,
          },
        },
        {
          path: "detail/:id",
          name: "Inventory Transfer Detail",
          component: () => import("@/views/pages/inventoryTransfer/detail"),
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/inventory-transfer",
            parent: "/inventory-transfer",

            lv: 3,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default InventoryTransfer;
