import { PersonalRoute, CompanyRoute } from "./RegisterForm";
import { ShippingRoute } from "./shipping";
import InventoryTransfer from "./InventoryTransfer";
const Setting = {
  path: "",
  name: "",
  redirect: "/setting",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/setting",
      redirect: "/setting",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Setting",
      meta: {
        requiresAuth: true,
        activeUrl: "/setting",

        lv: 2,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/setting"),
          meta: {
            activeUrl: "/setting",
            isChildren: true,
            requiresAuth: true,

            lv: 2,
          },
        },
        {
          path: "policy",
          name: "Setting Detail",
          component: () =>
            import("@/views/pages/setting/settingPage/DetailPolicy.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "bussinessinfo",
          name: "Business Information",
          component: () =>
            import("@/views/pages/setting/settingPage/BusinessInfo.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "pdpasetting",
          name: "Email Template",
          component: () =>
            import("@/views/pages/setting/settingPage/EmailPDPA.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "membership",
          name: "Member",
          component: () =>
            import("@/views/pages/setting/settingPage/SettingMemberShip.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "term",
          name: "Term and condition",
          component: () =>
            import("@/views/pages/setting/settingPage/DetailTerm.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "line",
          name: "Line Liff",
          component: () =>
            import("@/views/pages/setting/settingPage/LineSetting"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "general",
          name: "System",
          component: () => import("@/views/pages/setting/settingPage/General"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "",
          name: "",
          redirect: "ecommerce",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "ecommerce",
              name: "Ecommerce",
              redirect: "ecommerce",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Ecommerce"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Ecommerce Detail",
                  redirect: "/setting/ecommerce",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Ecommerce/detail.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    {
                      path: ":LogID",
                      name: "Log Detail",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Ecommerce/LogDetail.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "booking",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "booking",
              name: "Booking Setting",
              redirect: "Booking",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Booking"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Booking Setting Detail",
                  redirect: "/setting/booking",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Booking/detail"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                    //   path: ":LogID",
                    //   name: "Log Detail",
                    //   component: () =>
                    //     import(
                    //       "@/views/pages/setting/settingPage/Ecommerce/LogDetail.vue"
                    //     ),
                    //   meta: {
                    //     requiresAuth: true,
                    //
                    //     activeUrl: "/setting",
                    //     isChildren: true,
                    //     lv: 2,
                    //   },
                    // },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "payment-type",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "payment-type",
              name: "Payment Type",
              redirect: "payment-type",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/PaymentType"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Payment Type Detail",
                  redirect: "/setting/payment-type",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/PaymentType/detail"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "branch-group",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "branch-group",
              name: "Branch Group",
              redirect: "branch-group",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/BranchGroup"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Branch Group Detail",
                  redirect: "/setting/branch-group",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/BranchGroup/detail"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "age-group",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "age-group",
              name: "Age Group",
              redirect: "age-group",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/AgeGroup"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Age Group Detail",
                  redirect: "/setting/age-group",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/AgeGroup/detail"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "report",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "report",
              name: "Report",
              redirect: "report",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Report"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Customer Report",
                  redirect: "/setting/report",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Report/detail"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "receipt-menu",
          name: "Receipt Menu",
          component: () =>
            import("@/views/pages/setting/settingPage/Receipt/MenuIndex.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "receipt/status",
          name: "Receipt Status",
          component: () =>
            import("@/views/pages/setting/settingPage/Receipt/Status.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "",
          name: "",
          redirect: "receipt",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "receipt",
              name: "Receipt",
              redirect: "receipt",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Receipt"),
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: "monitor",
                  name: "Receipt Monitor",
                  redirect: "/setting/receipt",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: ":id",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Receipt/Monitor"
                        ),
                      meta: {
                        requiresAuth: true,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    {
                      path: ":receipt_id/detail/:id",
                      name: "Receipt Monitor Detail",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Receipt/Monitor/detail"
                        ),
                      meta: {
                        requiresAuth: true,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    {
                      path: ":receipt_id/create/0",
                      name: "Receipt Monitor Detail",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Receipt/Monitor/CreateMonitor.vue"
                        ),
                      meta: {
                        requiresAuth: true,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                  ],
                },
                {
                  path: ":id",
                  name: "Receipt Detail",
                  redirect: "/setting/receipt",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Receipt/detail"
                        ),
                      meta: {
                        requiresAuth: true,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          path: "theme",
          name: "Theme",
          component: () =>
            import("@/views/pages/setting/settingPage/SettingTheme.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "product-field",
          name: "Product Custom Field",
          component: () =>
            import("@/views/pages/setting/settingPage/ProductCustomField"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "otp-log",
          name: "OTP Log",
          component: () => import("@/views/pages/setting/settingPage/OtpLog"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "/update/term",
          name: "Term And Condition",
          component: () =>
            import("@/views/pages/setting/settingPage/UpdateTerm.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "/update/policy",
          name: "Policy",
          component: () =>
            import("@/views/pages/setting/settingPage/UpdatePolicy.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        // {
        //   path: "registerform",
        //   name: "Personal Customer",
        //   component: () =>
        //     import("@/views/pages/setting/settingPage/RegisterForm.vue"),
        //   meta: {
        //     requiresAuth: true,

        //     activeUrl: "/setting",
        //     isChildren: true,
        //     lv: 2,
        //   },
        // },
        {
          path: "",
          name: "",
          redirect: "address",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "address",
              name: "Address",
              redirect: "address",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/Address/index.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: "shippingaddress",
                  name: "Shipping Address",
                  redirect: "/setting/shippingaddress",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Address/ShippingForm.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
                {
                  path: "billingaddress",
                  name: "Billing Address",
                  redirect: "/setting/billingaddress",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Address/BillingForm.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
                {
                  path: "contactaddress",
                  name: "Contact Address",
                  redirect: "/setting/contactaddress",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Address/ContactForm.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "plugins",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "plugins",
              name: "Plugins",
              redirect: "plugins",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,

                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/Plugins/index.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Plugins Info",
                  redirect: "/setting/plugins",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Plugins/detail.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
                {
                  path: "logs/:id",
                  name: "Plugins Log Info",
                  redirect: "/setting/plugins",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Plugins/logs.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "channel-usage",
          name: "Communication Channel Usage",
          component: () =>
            import("@/views/pages/setting/settingPage/ChannelUsage"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "",
          name: "",
          redirect: "socialmedia",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "socialmedia",
              name: "Social Media",
              redirect: "socialmedia",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/SocialMedia/index.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Social Media Detail",
                  redirect: "/setting/socialmedia",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/SocialMedia/detail.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "customer-type",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "customer-type",
              name: "Customer Type",
              redirect: "customer-type",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/CustomerType/index.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
              ],
            },
          ],
        },
        ShippingRoute,
        InventoryTransfer,
        // {
        //   path: "",
        //   name: "",
        //   redirect: "shipping-methods",
        //   component: {
        //     render(c) {
        //       return c("router-view");
        //     },
        //   },
        //   meta: {
        //     requiresAuth: true,
        //     activeUrl: "/setting",
        //     isChildren: true,
        //     lv: 2,
        //   },
        //   children: [
        //     {
        //       path: "shipping-methods",
        //       name: "Shipping Methods",
        //       redirect: "shipping-methods",
        //       component: {
        //         render(c) {
        //           return c("router-view");
        //         },
        //       },
        //       meta: {
        //         requiresAuth: true,
        //         activeUrl: "/setting",
        //         isChildren: true,
        //         lv: 2,
        //       },
        //       children: [
        //         {
        //           path: "",
        //           name: "",
        //           component: () =>
        //             import(
        //               "@/views/pages/setting/settingPage/Shipping/ShippingMethods/index.vue"
        //             ),
        //           meta: {
        //             requiresAuth: true,
        //             activeUrl: "/setting",
        //             isChildren: true,
        //             lv: 3,
        //           },
        //         },
        //         {
        //           path: ":id",
        //           name: "Shipping Methods Detail",
        //           redirect: "/setting/ShippingMethods",
        //           component: {
        //             render(c) {
        //               return c("router-view");
        //             },
        //           },
        //           meta: {
        //             requiresAuth: true,

        //             activeUrl: "/setting",
        //             isChildren: true,
        //             lv: 2,
        //           },
        //           children: [
        //             {
        //               path: "",
        //               name: "",
        //               component: () =>
        //                 import(
        //                   "@/views/pages/setting/settingPage/Shipping/ShippingMethods/detail.vue"
        //                 ),
        //               meta: {
        //                 requiresAuth: true,
        //                 activeUrl: "/setting",
        //                 isChildren: true,
        //                 lv: 2,
        //               },
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        {
          path: "",
          name: "",
          redirect: "customer-group",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "customer-group",
              name: "Customer Group",
              redirect: "customer-group",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/CustomerGroup/index.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Customer Group Detail",
                  redirect: "/setting/customer-group",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/CustomerGroup/detail.vue"
                        ),
                      meta: {
                        requiresAuth: true,

                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                    // {
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "ticket",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "ticket",
              name: "Ticket",
              redirect: "ticket",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Ticket"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: "field",
                  name: "Ticket Field",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/Ticket/TicketField/index.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: "form",
                  name: "Ticket Form",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/Ticket/TicketForm/index.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: "setting",
                  name: "Ticket Setting",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Ticket/Setting"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "webhook",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "webhook",
              name: "Webhook",
              redirect: "webhook",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/WebHook"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: "log",
                  name: "Webhook Log",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/WebHook/webhookLog.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
              ],
            },
          ],
        },
        {
          path: "chart-theme",
          name: "chartTheme",
          component: () =>
            import("@/views/pages/setting/settingPage/SettingChartTheme.vue"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
        {
          path: "",
          name: "",
          redirect: "brand",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "brand",
              name: "Manufacturer",
              redirect: "brand",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Brand/index.vue"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Brand Detail",
                  redirect: "/setting/brand",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/Brand/detail"
                        ),
                      meta: {
                        requiresAuth: true,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "team",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "team",
              name: "Team",
              redirect: "team",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Team"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Team Detail",
                  redirect: "/setting/team",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import("@/views/pages/setting/settingPage/Team/detail"),
                      meta: {
                        requiresAuth: true,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "rich-menu",
          name: "Rich Menu",
          redirect: "rich-Menu",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
          children: [
            {
              path: "",
              name: "",
              redirect: "richMenu",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                activeUrl: "/setting",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/setting/settingPage/RichMenu/Index.vue"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 3,
                  },
                },
                {
                  path: ":id",
                  name: "Rich Menu Detail",
                  redirect: "/setting/rich-menu",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "",
                      name: "",
                      component: () =>
                        import(
                          "@/views/pages/setting/settingPage/RichMenu/Detail"
                        ),
                      meta: {
                        requiresAuth: true,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        PersonalRoute,
        CompanyRoute,
        {
          path: "language",
          name: "Language",
          component: () => import("@/views/pages/setting/settingPage/Language"),
          meta: {
            requiresAuth: true,

            activeUrl: "/setting",
            isChildren: true,
            lv: 2,
          },
        },
      ],
    },
  ],
};

export default Setting;
