<template>
  <b-form-checkbox
    id="includes_anonymous"
    v-model="val"
    name="includes_anonymous"
    :value="1"
    :unchecked-value="0"
  >
    Allow Includes Anonymous User
  </b-form-checkbox>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
    },
  },
  computed: {
    val: {
      get: function () {
        return this.value;
      },
      // setter
      set: function (newValue) {
        this.$emit("change", newValue);
        this.$store.dispatch("setFilter", {
          key: "allow_anonymous",
          value: newValue,
        });
      },
    },
  },
};
</script>

<style></style>
